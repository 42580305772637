const constructedFieldValue = {
  methods: {
    constructFieldValuesBasedOnType (moduleDataObj, listOfFields) {
      const constructedData = {}
      listOfFields.forEach((field) => {
        if (field.isactive) {
          const fieldInfo = field
          fieldInfo.default_value = fieldInfo.default_value ? JSON.parse(fieldInfo.default_value) : {}
          constructedData[fieldInfo.name] = {
            value: moduleDataObj.data[fieldInfo.name],
            text: moduleDataObj.data[fieldInfo.name],
            fieldProperties: { ...fieldInfo, value: moduleDataObj.data[fieldInfo.name] }
          }
          switch (fieldInfo.type) {
            case 3:
              constructedData[fieldInfo.name].text = moduleDataObj.data[fieldInfo.name] ? this.$t('yes') : this.$t('no')
              break
            case 4: {
              const value = fieldInfo.default_value.options.find(x => x.value === moduleDataObj.data[fieldInfo.name])
              if (value) constructedData[fieldInfo.name].text = value.label
              break
            }
            case 5:
              switch (fieldInfo.default_value.selectType) {
                case 'default':
                  if (moduleDataObj.data[fieldInfo.name] && moduleDataObj.data[fieldInfo.name].length) {
                    constructedData[fieldInfo.name].text = moduleDataObj.data[fieldInfo.name].map(x => { return x.name }).join(', ')
                    constructedData[fieldInfo.name].value = moduleDataObj.data[fieldInfo.name].map(x => { return x.value })
                  } else constructedData[fieldInfo.name].text = constructedData[fieldInfo.name].value = null
                  if (!fieldInfo.default_value.is_multiselect && moduleDataObj.data[fieldInfo.name].length) {
                    constructedData[fieldInfo.name].text = moduleDataObj.data[fieldInfo.name][0].name
                    constructedData[fieldInfo.name].value = moduleDataObj.data[fieldInfo.name][0].value
                    constructedData[fieldInfo.name].color = moduleDataObj.data[fieldInfo.name][0].color
                    constructedData[fieldInfo.name].fontcolor = this.$formatter.foreGroundColor(moduleDataObj.data[fieldInfo.name][0].color)
                  }
                  break
                case 'module':
                  if (moduleDataObj.data[fieldInfo.name] && moduleDataObj.data[fieldInfo.name].length) {
                    constructedData[fieldInfo.name].text = moduleDataObj.data[fieldInfo.name].map(x => x.data.name).join(', ')
                    constructedData[fieldInfo.name].value = fieldInfo.default_value.is_multiselect ? moduleDataObj.data[fieldInfo.name].map(x => x._id) : moduleDataObj.data[fieldInfo.name][0]._id
                    constructedData[fieldInfo.name].fieldProperties.default_value.options = moduleDataObj.data[fieldInfo.name]
                  } else {
                    constructedData[fieldInfo.name].text = ''
                    if (field.default_value.is_multiselect && typeof constructedData[fieldInfo.name].value === 'object') constructedData[fieldInfo.name].value = []
                  }
                  break
                case 'custom':
                  if (fieldInfo.default_value.is_multiselect && moduleDataObj.data[fieldInfo.name] && moduleDataObj.data[fieldInfo.name].length) {
                    constructedData[fieldInfo.name].text = this.$formatter.cloneVariable(moduleDataObj.data[fieldInfo.name].join(', '))
                    constructedData[fieldInfo.name].options = []
                    moduleDataObj.data[fieldInfo.name].forEach((x) => {
                      constructedData[fieldInfo.name].options.push({ name: x })
                    })
                  } else {
                    constructedData[fieldInfo.name].text = fieldInfo.default_value.is_multiselect ? '' : moduleDataObj.data[fieldInfo.name] || ''
                    constructedData[fieldInfo.name].options = moduleDataObj.data[fieldInfo.name] ? [moduleDataObj.data[fieldInfo.name]] : []
                  }
                  break
              }
              break
            case 6: {
              // const date = moduleDataObj.data[fieldInfo.name] ? this.$formatter.fromUtcToLocal(moduleDataObj.data[fieldInfo.name], 'DD.MM.YYYYTHH:mm:ss') : null
              // constructedData[fieldInfo.name].text = date ? this.$formatter.formatDate(date, '', 'DD.MM.YYYY') : null

              constructedData[fieldInfo.name].text = (moduleDataObj.data[fieldInfo.name] && constructedData[fieldInfo.name])
                ? this.$formatter.formatDate(moduleDataObj.data[fieldInfo.name], '', this.userDetails.dateformat)
                : null
              break
            }
            case 15:
              if (moduleDataObj.data[fieldInfo.name] && moduleDataObj.data[fieldInfo.name].length) {
                const fieldValue = field.value === 'created_by' || field.value === 'modified_by' ? moduleDataObj[field.value] : moduleDataObj.data[fieldInfo.name]
                constructedData[fieldInfo.name].text = fieldValue.map(e => `${e.firstname} ${e.lastname || ''}`).join(', ')
                constructedData[fieldInfo.name].value = fieldInfo.default_value.is_multiselect ? fieldValue.map(e => e._id) : fieldValue[0]._id
              } else {
                constructedData[fieldInfo.name].text = ''
                constructedData[fieldInfo.name].value = fieldInfo.default_value.is_multiselect ? [] : null
              }
              break
            case 16: {
              constructedData[fieldInfo.name].text = (moduleDataObj.data[fieldInfo.name] && constructedData[fieldInfo.name])
                ? this.$formatter.formatDate(moduleDataObj.data[fieldInfo.name], '', `${this.userDetails.dateformat} HH:mm`)
                : null
              constructedData[fieldInfo.name].value = (moduleDataObj.data[fieldInfo.name] && constructedData[fieldInfo.name])
                ? this.$formatter.formatDate(moduleDataObj.data[fieldInfo.name], '', 'YYYY-MM-DDTHH:mm:ss')
                : null
            }
          }
        }
      })
      return constructedData
    }
  }
}
export {
  constructedFieldValue
}

<template>
  <div>
    <v-row class="mt-0" v-if="fieldsLoading">
      <v-col v-for="(item) in 12" :key="item" cols="12" sm="6" lg="3" class="pa-1">
        <v-sheet>
          <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>
    <v-app-bar app :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }`" tile :height="activeSubModule || fieldsLoading ? 0 : 100" width="100%" class="record-viewpage"
      v-if="!activeSubModule" elevation="7">
      <template>
        <v-container fluid class="pa-0" ref="recordview">
          <v-row no-gutters class="py-3">
            <v-col cols="9" class="text-truncate" :style="`color: ${systemDetails.textcolor}`">
              <v-btn class="ml-1 mr-2" icon small dark @click="$router.push(`/module/${moduleName}/${moduleId}`)">
                <v-icon size="20"> mdi-arrow-left </v-icon>
              </v-btn>
              <v-avatar v-if="currentModule && currentModule.enableprofile && !fieldsLoading" :color="moduleObj.profileimage ? '' : 'orange'" size="35">
                <img v-if="!moduleObj._id || moduleObj.profileimage" :src="(moduleObj._id) ? moduleObj.profileimage :  moduleObj.profileimage ? moduleObj.profileimage : '@/assets/profile.jpg'" alt="profile">
                <span v-else class="body-2 font-weight-medium white--text"> {{ moduleObj.data.name ? moduleObj.data.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() : '' }} </span>
              </v-avatar>
              <span :class="['subtitle-1 font-weight-medium', (currentModule && currentModule.enableprofile) ? 'mx-3' : '']" v-if="moduleObj.data">
                {{ moduleObj.data.name }}
              </span>
            </v-col>
            <v-col cols="3" class="d-flex justify-end" v-if="recordActionsObj.list.some((listItem) => !!listItem.show) && tab === 'overview'">
              <!-- <v-menu>
                <template #activator="{ on, attrs }">
                  <v-btn class="ma-auto" icon dark height="25" width="25" v-on="on" v-bind="attrs">
                    <v-icon size="18"> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <template>
                  <v-card class="pb-2 pt-0">
                    <template v-for="(listItem, listItemIndex) in recordActionsObj.list">
                      <v-list-item v-if="listItem.show" :key="listItemIndex" @click="listItem.click()" class="py-2 my-0 pl-4 pr-5 custom-vlist-item mb-n2">
                        <v-list-item-action v-if="listItem.icon" class="my-1 mr-0">
                          <v-icon size="15" :color="listItem.color"> {{ listItem.icon }} </v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="caption">
                            {{ $t(listItem.text) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-card>
                </template>
              </v-menu> -->
              <div v-for="(listItem, listItemIndex) in recordActionsObj.list" :key="listItemIndex">
                <v-icon v-if="listItem.show" @click="listItem.click()" :color="listItem.color" class="mr-3 mt-3" small>{{ listItem.icon }}</v-icon>
              </div>
              <!-- <v-icon class="primary--text mr-2" small>mdi-pencil</v-icon>
              <v-icon class="red--text" small>mdi-delete</v-icon> -->
            </v-col>
          </v-row>
          <v-row no-gutters v-if="!fieldsLoading">
            <v-col cols="12">
              <v-layout row wrap align-center class="ma-0">
                <v-tabs id="tab" sm2 centered v-model="tab" :color="!$vuetify.theme.dark ? systemDetails.themecolor : ''" grow height="50" :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'">
                  <v-tab v-for="(i, index) in tabMenu" :key="index" :href="`#${i.value}`">
                    <div :class="tab === i.value ? 'tab--icon' : 'mr-2'">
                      <v-icon size="20" class="mr-3">{{ i.icon }}</v-icon></div>
                    {{ i.name }}
                  </v-tab>
                </v-tabs>
              </v-layout>
            </v-col>
          </v-row>
          <v-progress-linear v-if="recordLoad" :color="systemDetails.themecolor" indeterminate></v-progress-linear>
        </v-container>
      </template>
    </v-app-bar>
    <v-container fluid class="pa-0">
      <v-row class="mt-0" v-if="fieldsLoading">
        <v-col v-for="(item) in 12" :key="item" cols="12" sm="6" lg="3" class="pa-1">
          <v-sheet>
            <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!fieldsLoading && !activeSubModule">
        <v-col cols="12">
          <v-layout row wrap align-center class="ma-0">
            <!-- <v-tabs id="tab" sm2 centered v-model="tab" :color="!$vuetify.theme.dark ? systemDetails.themecolor : ''" grow height="50" :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'">
              <v-tab v-for="(i, index) in tabMenu" :key="index" :href="`#${i.name}`">
                <div :class="tab === i.name ? 'tab--icon' : 'mr-2'">
                  <v-icon size="20" class="mr-3">{{ i.icon }}</v-icon></div>
                {{ i.name }}
              </v-tab>
            </v-tabs> -->
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(i, index) in tabMenu" :key="index" :value="i.value" :eager="true" style="width: 100vw">
                <v-card flat :color="!$vuetify.theme.dark ? '#f9f9f9' : ''">
                  <v-card-text class="px-2">
                    <template v-if="i.id === 1 && tab === `${i.value}`">
                      <v-container class="px-2" v-if="moduleObj.data">
                        <template v-for="(record, i) in recordValueObj">
                          <v-row class="mr-1 body-2" :key="i" v-if="(record.fieldProperties._id === 'created_by' ? !(!!moduleObj.isanonymous) : true) && record.fieldProperties.name !== 'isanonymous' && record.fieldProperties.name !== 'routingstatus'">
                            <v-col cols="12">
                              <span class="body-2 mr-2 grey--text text--darken-2"> {{ $t(record.fieldProperties.label) }}: </span>
                              <span class="font-weight-medium grey--text text--darken-2">
                                <v-icon size="15" v-if="record.fieldProperties.type === 12 &&  record.text" @click="openMail(record.text)" color="primary">
                                  mdi-email-variant
                                </v-icon>
                                <v-icon size="15" v-if="record.fieldProperties.type === 14 && record.text" @click="openDialpad(record.text)" color="green">
                                  mdi-phone
                                </v-icon>
                                <template v-if="record.fieldProperties.type === 15 && !record.fieldProperties.default_value.is_multiselect">
                                  <v-avatar class="mr-1" v-if="record && record.value" size="20" :color="record.profile ? '' : 'indigo'">
                                    <img v-if="record.profile" :src="record.profile ? $formatter.getAppImageURL()+'/'+ record.profile : ''" alt="Profile"/>
                                    <span v-else class="caption white--text">
                                      {{ record && record.text ? record.text.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() : '' }}
                                    </span>
                                  </v-avatar>
                                </template>
                                <span v-if="record.fieldProperties.type === 10">
                                  <v-img :src="record.value" max-height="60px" max-width="100px" contain></v-img>
                                </span>
                                <span v-else-if="record.fieldProperties.type === 3">
                                  <v-icon v-if="record.value" size="17" class="mr-1" color="success"> mdi-checkbox-marked-circle </v-icon>
                                  <v-icon v-else size="17" class="mr-1" color="error"> mdi-close-circle </v-icon>
                                </span>
                                <span v-else-if="record.fieldProperties.type === 19" v-html="record.text"></span>
                                <template v-else-if="record.fieldProperties.type === 20">
                                <span>
                                  <v-badge overlap bordered color="green" class="pt-1 mx-0 mt-3" :content="`${getRating(record.text)}`">
                                    <v-icon size="24" color="#0598ed">mdi-account-star</v-icon>
                                  </v-badge>
                                </span>
                               </template>
                                <span v-else>
                                  {{ record.text || '--' }}
                                </span>
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                      </v-container>
                    </template>
                    <template v-if="i.id === 2 && tab === `${i.value}`">
                      <timeline-view :moduleObj="moduleObj" :currentModule="currentModule" ref="timelineView" :paginationObj="paginationObj"></timeline-view>
                    </template>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-layout>
        </v-col>
      </v-row>
      <v-row v-if="activeSubModule" class="mt-3 pl-3 pr-3">
        <v-col cols="12" xs="12" class="pa-0">
          <template v-if="activeSubModule.name === MEMBERS">
            <project-members :key="reRender" :hasPermissionTo="$formatter.cloneVariable(moduleObj.accesscontrol)"></project-members>
          </template>
          <template v-else-if="activeSubModule.name === TICKET">
            <tickets :key="reRender" :hideFilters="true" :moduleName="moduleName" :recordId="recordId"></tickets>
          </template>
          <template v-else-if="activeSubModule.name === DOCUMENTS && !activeSubModule._id">
            <documents :key="reRender" :sharepointId="moduleObj.sharepoint_id" :name="mainName" :recordObj="moduleObj" v-if="userDetails.issharepointstorage"
              :formattedObj="$formatter.cloneVariable({ ...recordValueObj, ...{ name: moduleObj.data && moduleObj.data.name } })">
            </documents>
             <azure-documents v-else
              :name="mainName" :recordObj="moduleObj"
              :formattedObj="$formatter.cloneVariable({ ...recordValueObj, ...{ name: moduleObj.data && moduleObj.data.name } })">
            </azure-documents>
          </template>
          <template v-else-if="activeSubModule.name === NOTES && !activeSubModule._id">
            <notes :accesscontrol="$formatter.cloneVariable(moduleObj.accesscontrol)"></notes>
          </template>
          <template v-else-if="activeSubModule.name === BOOKING_IMAGE">
            <booking-images :moduleName="moduleName" :recordId="recordId"></booking-images>
          </template>
          <template v-else-if="activeSubModule.name === CHECKLIST && !activeSubModule._id">
            <checklist :module="moduleId" :accesscontrol="moduleObj.accesscontrol"></checklist>
          </template>
          <template v-else-if="activeSubModule.name === QUOTE && !activeSubModule._id">
            <quote></quote>
          </template>
          <template v-else>
            <submodule-list :moduleObj="activeSubModule" :moduleIcon="moduleInfo.icon" :hasPermissionTo="$formatter.cloneVariable(moduleObj.accesscontrol)"
              :parentModuleItem="$formatter.cloneVariable(currentModule)" @redirectionToModuleView="redirectionToModuleView($event)">
            </submodule-list>
          </template>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-speed-dial v-model="showItems" bottom right fixed class="custom-speed-dial" v-if="tab === 'overview' && listOfSubModules.length">
          <template #activator>
            <v-btn v-model="showItems" :color="$vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" dark fab small
              :outlined="$vuetify.theme.dark" class="mr-2 mb-1" @click="activateSubmodules=true">
              <v-icon v-if="showItems"> mdi-close </v-icon>
              <v-icon v-else> mdi-menu </v-icon>
            </v-btn>
          </template>
          <!-- <v-btn v-for="item in submodules" :key="`${item.plural_name}_menuitem`" class="text-capitalize ma-0 mb-1" small min-width="80px" @click="setActiveSubModule(item)"
            :style="{ color: systemDetails && systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
            :color="$vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'">
            {{ $t(item.plural_label) }}
          </v-btn> -->
           <v-menu top left content-class="elevation-0">
            <template v-if="activateSubmodules" v-slot:activator="{ on, attrs }">
              <v-btn  v-bind="attrs" v-on="on" v-if="listOfSubModuleMore.length" class="text-capitalize ma-0 mb-1" small max-width="150px"
                :color="$vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'"
                :style="{ color: systemDetails && systemDetails.textcolor ? systemDetails.textcolor : 'white' }" @click="showItemsMoreFields()">
                <span class="d-inline-block text-truncate" style="max-width:150px"> {{$t('showMore')}} </span>
              </v-btn>
            </template>
            <v-list  flat style="background-color:rgba(255, 99, 71, 0);" tile :height="$vuetify.breakpoint.xsOnly ?'440':'700'"  class="px-0 py-0 pt-1 inner-border" dense>
              <v-list-item-group>
                <v-list-item  color="transparent" class="pa-0 list-size-reduce" v-for="(item, fieldIndex) in submodules " :key="fieldIndex">
                  <v-btn v-if="hideList" class="text-capitalize text-truncate ma-0 mb-1" small max-width="150px" @click="setActiveSubModule(item)"
                    :style="{ color: systemDetails && systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
                    :color="$vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'">
                    <span class="d-inline-block text-truncate" style="max-width:150px">{{ $t(item.plural_label) }}</span>
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <template v-if="activateSubmodules">
           <v-btn v-for="(item, itemIndex) in listOfSubModules " :key="itemIndex"  class="text-capitalize ma-0 mb-1" small max-width="150px" @click="setActiveSubModule(item)"
            :style="{ color: systemDetails && systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
            :color="$vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'">
            <span class="text-truncate" style="max-width:150px">{{ $t(item.plural_label) }}</span>
           </v-btn>
          </template>
        </v-speed-dial>
      </v-row>
    </v-container>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import CommonFunctions from './mixin'
import { constructedFieldValue } from '../../mixins/commonfunctions'
export default {
  mixins: [CommonFunctions, constructedFieldValue],
  data () {
    return {
      reRender: 0,
      moduleName: this.$route.params.name,
      recordId: this.$route.params.record_id,
      moduleId: this.$route.params.id,
      panels: [],
      enableEditMode: false,
      moduleObj: {
        submodules: []
      },
      submodules: [],
      screenHeight: 0,
      activateSubmodules: true,
      hideList: true,
	    listOfSubModuleMore: [],
      listOfSubModules: [],
      moduleInfo: {},
      tab: 'overview',
      /* pwa */
      showItems: false,
      activeSubModule: '',
      fieldsLoading: false,
      mainName: '',
      canShowTicket: false,
      canShowChecklist: false,
      imageURL: process.env.VUE_APP_IMAGE_URL,
      domain: '',
      paginationObj: {
        page: 1,
        itemsPerPage: 25
      },
      recordValueObj: {},
      recordLoad: true
    }
  },
  components: {
    'submodule-list': () => import('./SubmoduleList.vue'),
    'documents': () => import('./Documents.vue'),
    'notes': () => import('./Notes.vue'),
    'checklist': () => import('./Checklist.vue'),
    'project-members': () => import('./ProjectMembers.vue'),
    'tickets': () => import('../Tickets/List.vue'),
    'quote': () => import('./Sales/Quotes.vue'),
    'booking-images': () => import('./BookingImages.vue'),
    'timeline-view': () => import('./Timeline.vue'),
    'azure-documents': () => import('./AzureDocuments.vue')
  },
  mounted () {
    // this.currentModule = this.listOfModules.find(x => x._id === this.moduleId)
    // if (this.currentModule) this.profileEnabled = currentModule.enableprofile
    this.domain = this.userDetails.domain
    if (this.userDetails.allowedfeatures && this.userDetails.allowedfeatures.length > 0) {
      this.canShowTicket = this.userDetails.allowedfeatures.includes(this.TICKETS)
      this.canShowChecklist = this.userDetails.allowedfeatures.includes(this.CHECKLISTS)
    }
    this.fieldsLoading = true
    this.$root.$on('callRerender', () => {
      this.reRender++
    })
    this.$root.$on('hideSubmoduleView', () => {
      this.activeSubModule = null
    })
    this.$store.dispatch('getModuleList').then(() => {
      this.getSingleRecordHandler()
    })
    this.$eventBus.$on('updateTaskStatus', (status) => {
      this.recordValueObj.status = {
        ...this.recordValueObj.status,
        name: status,
        value: status,
        text: this.$t(status)
      }
    })
    // window.addEventListener('resize', () => { // To construct current screen height
    //   this.constructSubmodulesMore ()
    // })
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails', 'getUsers', 'systemDetails']),
    currentModule () {
      return this.listOfModules.find(x => x._id === this.moduleId)
    },
    tabMenu () {
      return [{
        id: 1,
        name: this.$t('overview'),
        value: 'overview',
        icon: 'mdi-note-text'
      }, {
        id: 2,
        name: this.$t('timeline'),
        value: 'timeline',
        icon: 'mdi-timeline-text'
      }]
    },
    recordActionsObj () {
      return {
        menu: false,
        list: [{
          text: 'edit',
          icon: 'mdi-pencil',
          color: this.$vuetify.theme.dark ? '' : this.systemDetails.textcolor,
          click: () => this.$router.push(`/module/${this.moduleName}/${this.moduleId}/actions/${this.moduleObj._id}`),
          show: this.moduleObj.accesscontrol && this.moduleObj.accesscontrol.edit
        }, {
          text: 'delete',
          icon: 'mdi-delete',
          color: this.$vuetify.theme.dark ? '' : this.systemDetails.textcolor,
          click: this.deleteRecordHandler,
          show: this.moduleObj.accesscontrol && this.moduleObj.accesscontrol.delete
        }, {
          text: 'joinMeetingOnline',
          icon: 'mdi-web-clock',
          color: this.$vuetify.theme.dark ? '' : 'success',
          click: () => this.redirectToMeetingLink(),
          show: this.currentModule && this.currentModule.name === 'Event' && this.moduleObj.meetingurl
        }]
      }
    }
  },
  watch: {
    // '$store.state.common.listOfModules' (val) {
    //   this.setPermission()
    // }
  },
  methods: {
    getSingleRecordHandler () {
      this.moduleInfo = this.listOfModules.find((moduleItem) => moduleItem.name === this.moduleName)
      // Load default submodules for current module
      this.loadCorrespondingSubModules ()
      this.$api.execute('get', `moduledata/${this.moduleName}/get_by_id/${this.recordId}`).then(response => {
        if (response.status !== 204) {
          if (response) {
            response.data.data = {
              ...response.data.data,
              created_at: response.data.created_at,
              created_by: response.data.created_by,
              modified_at: response.data.modified_at,
              modified_by: response.data.modified_by
            }
            this.moduleObj = Object.assign({}, this.moduleObj, response.data)
             if (this.moduleObj.profileimage) {
                if (this.moduleObj.profileimage.includes('http')) {
                  this.moduleObj.profileimage = this.moduleObj.profileimage
                } else {
                  this.moduleObj.profileimage = `${process.env.VUE_APP_IMAGE_URL}${this.userDetails.domain}/module_profiles/${this.moduleObj.profileimage}`
                }
            }
            if (!this.moduleObj.accesscontrol.view) this.$router.push('/notavailable')
            this.mainName = this.moduleObj.data.name
            const model = {
              moduleid: this.moduleId,
              isactive_alone: true,
              include_default_fields: true,
              exceptfieldtypes: [9, 11]
            }
            this.$api.execute('post', 'modulefields/filter', model)
              .then(({ data }) => {
                if (!this.userDetails.isadmin) data = data.filter((field) => (field.name !== 'create_for' && field.label !== 'Event_create_for'))
                let value = this.constructFieldValuesBasedOnType(this.moduleObj, data)
                this.recordValueObj = value
                this.recordLoad = false
              }).finally(() => {
                this.$api.execute('get', `modules/${this.moduleId}`)
                  .then(response => {
                    let modulesResponseData = this.submodules.concat(response.data.submodules)
                    modulesResponseData.forEach(item => {
                      this.listOfModules.forEach(element => {
                        if (item === element.name && (element.isactive || element.showonlyassubmodule)) {
                          this.submodules.push({ _id: element._id, name: element.name, plural_label: element.plural_label, icon: element.icon })
                        }
                      })
                    })
                    // Pushing Images for bookable modules
                    if (this.listOfModules.find(x => x._id === this.moduleId).isbookable) this.submodules.push({ name: this.BOOKING_IMAGE, plural_label: 'images', _id: '', icon: 'mdi-folder-multiple-image'  })
                    // Pushing submodules if notes & checklist is checked in module creation
                    if (this.moduleInfo.include_notes)  this.submodules.push({ _id: '', name: this.NOTES, plural_label: 'notes', icon: 'mdi-note' })
                    if (this.moduleInfo.include_checklist && this.canShowChecklist) this.submodules.push({ _id: '', name: this.CHECKLIST, plural_label: 'checklist', icon: 'mdi-book-plus'  })
                       this.constructSubmodulesMore ()
                  })
                /* Once loaded fully if its sub modules is already opened then open that page */
                // let tempActiveSubModule = window.localStorage.getItem('store_activeSubModule')
                // tempActiveSubModule = tempActiveSubModule ? JSON.parse(tempActiveSubModule) : null
                // if (tempActiveSubModule) this.activeSubModule = tempActiveSubModule
                // else if (redirectActiveSubModule && !this.$route.query.redirect) this.activeSubModule = redirectActiveSubModule
                let redirectActiveSubModule = window.localStorage.getItem('set_activeSubModule') ? JSON.parse(window.localStorage.getItem('set_activeSubModule')) : null
                if (this.$route.query.redirect) this.moduleObj.saveActiveSubModule = true
                if (redirectActiveSubModule) {
                  let index = redirectActiveSubModule.findIndex(x => x.recordId === this.moduleObj._id)
                  if (index === -1) this.activeSubModule = ''
                  else {
                    this.activeSubModule = redirectActiveSubModule[index]
                    redirectActiveSubModule.splice(index, 1)
                    window.localStorage.setItem('set_activeSubModule', JSON.stringify(redirectActiveSubModule))
                  }
                }
              })
          }
        } else this.$router.push('/dashboard')
      }).finally(()=>{
        if (this.$route.query && this.$route.query.docid) this.activeSubModule = { _id: undefined, icon: "mdi-file-document-box", name: "Documents", plural_label: 'documents' }
        this.fieldsLoading = false
      })
    },
    loadCorrespondingSubModules () {
      // Pushing Ticket Module for account
      if ((this.moduleName === this.ACCOUNT || (this.moduleInfo && this.moduleInfo.include_ticket)) && this.canShowTicket) this.submodules.push({ name: this.TICKET, plural_label: 'tickets', _id: undefined, icon: 'mdi-palette-swatch'  })
      if (this.moduleName ===  this.SALE) this.submodules.push({ name: this.QUOTE, plural_label: 'Module_Plural_Quote', _id: undefined, icon: 'mdi-file-document-edit'  })
      // Pushing default submodules for parent module
      if (this.moduleInfo?.include_documents && (this.userDetails.issharepointstorage ? this.userDetails.sharepointconfigdone : true)) this.submodules.push({ name: this.DOCUMENTS, plural_label: 'documents', _id: undefined, icon: 'mdi-file-document-box'  })
      if (this.moduleName === this.PROJECT) this.submodules.push({ name: this.MEMBERS, plural_label: 'members', _id: undefined, icon: 'mdi-account-multiple-plus'  })
      if (this.currentModule.enablechildrelation) this.submodules.push({ name: this.currentModule.name, plural_label: `${this.$t('sub')}-${this.$t(this.currentModule.plural_label)}`, _id: this.currentModule._id, icon: this.currentModule.icon, isChildrenOfTheModule: true })
    },
    setActiveSubModule (item) {
      this.activeSubModule = item
      this.showItems = false
      // window.localStorage.setItem('store_activeSubModule', JSON.stringify(item))
    },
    removeActiveModule () {
      this.activeSubModule = null
      // window.localStorage.removeItem('store_activeSubModule')
      window.localStorage.removeItem('set_activeSubModule')
    },
    refreshList () {
      this.$eventBus.$emit('refreshList')
    },
    openMail (email) {
      window.location.href = `mailto:${email}?subject=&body=`
    },
    openDialpad (number) {
      window.location.href = `tel:${number}`
    },
    deleteRecordHandler () {
      this.$root.$emit('showPermanentDeleteDialog', {
        ids: [this.moduleObj._id],
        singleDelete: true,
        onResolve: () => {
          this.$router.push(`/module/${this.currentModule.name}/${this.currentModule._id}`)
        }
      })
    },
    redirectToMeetingLink () {
      window.open(this.moduleObj.meetingurl, '_blank')
    },
    redirectionToModuleView ({ moduleObj, _id }) {
      moduleObj.recordId = this.$route.params.record_id
      this.moduleObj.saveActiveSubModule  = true
      let storedSubModule = window.localStorage.getItem('set_activeSubModule') ? JSON.parse(window.localStorage.getItem('set_activeSubModule')) : []
      if (storedSubModule.length) {
        if (!storedSubModule.find(x => x.recordId === moduleObj.recordId)) {
          storedSubModule.push(moduleObj)
          window.localStorage.setItem('set_activeSubModule', JSON.stringify(storedSubModule))
        }
      }
      else {
        window.localStorage.setItem('set_activeSubModule', JSON.stringify([moduleObj]))
      }
      this.$router.push(`/module/${moduleObj.name}/${moduleObj._id}/view/${_id}?redirect=submodule`)
    },
     constructSubmodulesMore () {
      let menus = this.submodules
      let screenHeight = window.innerHeight
      let availableHeight = screenHeight - 20 - 70 - 20 - 30 // 55px - Logo, 37 - Footer - 75 for more
      let value = Math.round(availableHeight / 45)
      if (availableHeight < 45) value = 0
      this.listOfSubModules = menus.slice(0, value)
      this.listOfSubModuleMore = menus.slice(value, menus.length)
    },
     showItemsMoreFields () {
      this.listOfSubModules = this.submodules
      this.activateSubmodules = false
      this.showlist = true
    },
    getRating (textArray) {
      if (Array.isArray(textArray)) {
        let total = 0
        textArray.forEach(item => {
          if (item.rating) {
            total += item.rating
          }
        })
        return total
      } else {
        return 0
      }
    },
  },
  beforeDestroy () {
    // window.localStorage.removeItem('store_activeSubModule')
    if (!this.$route.query.redirect) window.localStorage.removeItem('set_activeSubModule')
    this.$root.$off('callRerender')
    this.$root.$off('hideSubmoduleView')
    this.$root.$off('deleteSuccess')
    this.$eventBus.$off('updateTaskStatus')
  }
}
</script>
<style>
/* .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 46px !important;
}
.v-expansion-panel__header > .v-expansion-panel__header__icon {
  flex: 1 1 auto !important;
}
.v-expansion-panel-header {
  display: block !important;
} */
.custom-speed-dial {
  bottom: 11vh !important;
  right: 3vw !important;
}
.custom-speed-dial div.v-speed-dial__list {
  padding: 5px 0 !important;
  align-items: flex-end !important;
}
.record-viewpage .v-toolbar__content {
  padding: 0 !important;
}
/* .list-size-reduce .v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 1px !important;
} */
.list-size-reduce.v-list-item {
    min-height: 1px !important;
}
.inner-border {
		overflow-x: hidden;
		overflow-y: auto;
 }
.inner-border::-webkit-scrollbar {
		display: none;
		}
</style>
